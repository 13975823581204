import 'react-toastify/dist/ReactToastify.css';

import { init } from '@amplitude/analytics-browser';
import isPropValid from '@emotion/is-prop-valid';
import App from 'next/app';
import Router from 'next/router';
import { ToastContainer } from 'react-toastify';
import styled, { StyleSheetManager, ThemeProvider } from 'styled-components';

import Footer from '../components/footer';
import { HeaderContainer } from '../components/header/Header.container';
import { HowItWorksVideoModal, SignupFormModal } from '../components/modals';
import { ModalDispatchContext, ModalStateContext } from '../context/modalContext';
import {
  API_BASE_URL,
  BRAZE_API_KEY,
  BRAZE_SDK_ENDPOINT,
  BROWSER_AMPLITUDE_ID,
  BROWSER_ENVIRONMENT,
  FACEBOOK_PIXEL_ID,
} from '../globals';
import GlobalStyles from '../styles/global';
import theme, { newTheme } from '../styles/theme';
import { trackEvent } from '../utils/analytics';
import { initializeAmplitudeExperiments } from '../utils/analytics';
import { getDeviceType } from '../utils/getDeviceType';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

class MyApp extends App {
  constructor(props) {
    super(props);
    this.state = {
      loginVisible: false,
      signupVisible: false,
      promoBanner: null,
    };

    initializeAmplitudeExperiments();
  }

  componentDidMount() {
    init(BROWSER_AMPLITUDE_ID, undefined, {
      serverUrl: `${API_BASE_URL}/proxy/analytics/amplitude`,
      includeGclid: true,
      includeFbclid: true,
      includeUtm: true,
    });

    import('@braze/web-sdk').then(Braze => {
      Braze.initialize(BRAZE_API_KEY, { baseUrl: BRAZE_SDK_ENDPOINT });
    });

    window.addEventListener('click', trackEvent);

    import('react-facebook-pixel')
      .then(x => x.default)
      .then(ReactPixel => {
        ReactPixel.init(FACEBOOK_PIXEL_ID, undefined, {
          debug: BROWSER_ENVIRONMENT !== 'production',
        });
        ReactPixel.pageView();

        Router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }

  componentWillUnmount() {
    window.removeEventListener('click', trackEvent);
  }

  render() {
    const { deviceType, Component, pageProps, flags } = this.props;

    return (
      <StyleSheetManager shouldForwardProp={isPropValid}>
        <ThemeProvider theme={flags?.marketingBg === 'new' ? newTheme : theme}>
          <ModalStateContext.Provider value={this.state}>
            <ModalDispatchContext.Provider
              value={values => this.setState({ ...this.state, ...values })}
            >
              <Container>
                <GlobalStyles />
                <HeaderContainer deviceType={deviceType} promoBanner={this.state.promoBanner} />
                <Component {...pageProps} deviceType={deviceType} />
                <Footer />
                <SignupFormModal isOpen={this.state.signupVisible} />
                <HowItWorksVideoModal />
              </Container>
              <ToastContainer />
            </ModalDispatchContext.Provider>
          </ModalStateContext.Provider>
        </ThemeProvider>
      </StyleSheetManager>
    );
  }
}
MyApp.getInitialProps = async appContext => {
  const request = appContext.ctx.req;
  const deviceType = getDeviceType({ request });

  return { ...(await App.getInitialProps(appContext)), deviceType };
};

export default MyApp;
